import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Benefits as View } from '@views';
import { useDebounce } from '@hooks';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { getBenefitsCategories, getBenefits, resetPhases } from '@store/benefit/duck';
import { trackEvent } from '@utils/mixpanel';

const Benefits = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentLocation: { id, city, isWhiteLabeled } } = useSelector(store => (
    store.locationStore
  ));

  const { getBenefitsCategoriesPhase, getBenefitsPhase, getBenefitsError, categories } = useSelector(store => (
    store.benefitStore
  ));

  const [error, setError] = useState({});
  const [searchText, setSearchText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(location.state?.selectedCategory ?? 0);

  const debouncedInput = useDebounce(searchText, 300);

  const handleSearchTextChange = useCallback(e => {
    setSearchText(e.target.value);
  }, []);

  const handleSelectedCategoryChange = category => () => {
    setSelectedCategory(category);

    trackEvent('Benefits category selected', {
      scope: 'Benefits',
      Category: categories.find(cat => cat.id === category).name,
    });
  };

  useEffect(() => {
    const pageTitle = 'Mindspace: Benefits';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Benefits',
    });
  }, []);

  useEffect(() => {
    dispatch(getBenefitsCategories());
  }, [city]);

  useEffect(() => {
    if (getBenefitsPhase === FAILURE) {
      setError({
        status: getBenefitsError.data.error.status,
        message: getBenefitsError.data.error.message,
      });
      dispatch(resetPhases());
    }
  }, [getBenefitsPhase]);

  useEffect(() => {
    if (debouncedInput) {
      trackEvent('Benefits searched', {
        scope: 'Benefits',
        text: debouncedInput,
      });
    }
  }, [debouncedInput]);

  useEffect(() => {
    if (id) {
      dispatch(getBenefits({
        locationId: id,
        ...(debouncedInput && { search: debouncedInput }),
        ...(selectedCategory && { category: selectedCategory }),
      }));
    }
  }, [debouncedInput, selectedCategory, city]);

  return (
    <View
      error={error}
      isLoading={getBenefitsCategoriesPhase === LOADING}
      city={city}
      isActivated={isWhiteLabeled}
      searchText={searchText}
      onSearchTextChange={handleSearchTextChange}
      selectedCategory={selectedCategory}
      onSelectedCategoryChange={handleSelectedCategoryChange}
    />
  );
};

export default Benefits;
