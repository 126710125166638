import React, { useState, useCallback } from 'react';
import { bool, func, object, number, exact, string } from 'prop-types';

import { Icon, Loader } from '@atoms';
import { DateSelectingModal } from '@molecules';
import { BookingList, CreditsReport } from '@organisms';
import { Main } from '@templates';
import { getCurrency } from '@helpers/common';
import { dayjs } from '@utils';
import { Typography, Flex } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as Bookings } from '@assets/svgs/Bookings.svg';
import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';
import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

const BookingHistory = ({
  error,
  areBookingsLoading,
  isLoading,
  onDateChange,
  date,
  spent,
  bookings,
  isAdmin,
}) => {
  const isCreditReportEnabled = false;
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  const handleDateModalChangeState = useCallback(() => {
    setIsDateModalOpen(prevValue => !prevValue);
  }, []);

  const handleDateModalClose = useCallback(() => {
    setIsDateModalOpen(false);
    onDateChange(dayjs());
  }, []);

  const handleDateSelect = newDate => () => {
    onDateChange(newDate);
    handleDateModalChangeState();
  };

  return (
    <Main
      isWhiteLabelingOpen={false}
      isLoading={isLoading}
      error={error}
    >
      <Flex
        flexGrow={1}
        width="100%"
        alignItems="start"
        flexDirection="column"
      >
        <Typography variant="garamond-500" fontSize={[30, 34]} mb={30}>
          {isAdmin && isCreditReportEnabled ? 'Bookings and Credit Usage' : 'Room Booking History'}
        </Typography>
        <Flex mb={[20, 30]} position="relative">
          <Flex pointer onClick={handleDateModalChangeState} alignItems="center">
            <Typography mr="8px" variant="proximaNova-400" fontSize={16}>
              {`${date.format('MMMM')} ${date.format('YYYY')}`}
            </Typography>
            <Icon color={theme.color.gray[500]} SVG={Dropdown} height="5" />
          </Flex>
          {isDateModalOpen && !isLoading && (
            <DateSelectingModal
              onSelect={handleDateSelect}
              onModalClose={handleDateModalClose}
              calendarDate={date.$d}
              type="year"
            />
          )}
        </Flex>
        <Flex mb={[20, 30]} alignItems="center">
          <Icon mr={10} SVG={Bookings} height={16} />
          <Typography mr={20} fontSize={[12, 16]} variant="proximaNova-400">
            {`Total bookings: ${bookings}`}
          </Typography>
          <Icon mr={10} SVG={Credits} height={16} />
          <Typography mr={20} fontSize={[12, 16]} variant="proximaNova-400">
            {`Total credits used: ${Math.round(spent.credits * 100) / 100}`}
          </Typography>
          {!!spent.money && spent.currency && (
            <Typography mr={20} fontSize={[12, 16]} variant="proximaNova-400">
              {`Total paid: ${getCurrency(
                spent.currency, Math.round(spent.money * 100) / 100,
              )}`}
            </Typography>
          )}
        </Flex>
        {areBookingsLoading
          ? (
            <Flex width="100%" flexGrow={1} alignItems="center" justifyContent="center">
              <Loader position="relative" background={theme.color.white} width={64} height={64} />
            </Flex>
          )
          :
          (
            <>
              {isAdmin && isCreditReportEnabled && <CreditsReport />}
              <BookingList />
            </>
          )}
      </Flex>
    </Main>
  );
};

BookingHistory.defaultProps = {
  error: {},
};

BookingHistory.propTypes = {
  isAdmin: bool.isRequired,
  error: exact({
    status: number,
    message: string,
  }),
  areBookingsLoading: bool.isRequired,
  spent: exact({
    credits: number,
    money: number,
    currency: string,
  }).isRequired,
  bookings: number.isRequired,
  isLoading: bool.isRequired,
  onDateChange: func.isRequired,
  date: object.isRequired,
};

export default BookingHistory;
