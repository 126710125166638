import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { bool, func, shape, string } from 'prop-types';

import { Icon, TextArea } from '@atoms';
import { Typography, Flex, Button } from '@mixins';
import { routes } from '@constants';
import { Main } from '@templates';
import { theme } from '@styles';

import AmenitiesImage from '@assets/images/Amenities.jpg';
import { ReactComponent as Arrow } from '@assets/svgs/Arrow.svg';
import { ReactComponent as ThankYou } from '@assets/svgs/ThankYou.svg';
import { useSelector } from 'react-redux';

const Feedback = ({ user, onSubmit, isLoading, isSuccess }) => {
  const history = useHistory();

  const { navigationItems } = useSelector(store => (
    store.metadataStore
  ));

  const [text, setText] = useState('');

  const onTextChange = event => {
    setText(event.target.value);
  };

  const handleSupportClick = useCallback(() => {
    const navigationItemGetSupport = navigationItems.find(item => item.key === 'support');

    if (navigationItemGetSupport && navigationItemGetSupport.link) {
      window.open(navigationItemGetSupport.link);
    } else {
      history.push(routes.support);
    }
  }, []);

  const handleSubmitClick = () => {
    onSubmit(text);
  };

  const handleDoneClick = () => {
    history.push(routes.home);
  };

  return (
    <Main
      isWhiteLabelingOpen={false}
      isLoading={isLoading}
    >
      <Flex
        width={['100%', '100%', '85%', '100%']}
        alignSelf={['start', 'start', 'start', 'center']}
        flexDirection="column"
        maxWidth="1320px"
      >
        <Typography mb={[20, 30]} variant="garamond-500" fontSize={34}>
          Share Your Experience
        </Typography>
        <Flex
          width="100%"
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
        >
          {!isSuccess ? (
            <Flex flexDirection="column">
              <Typography variant="proximaNova-400" fontSize={[12, 14, 16]} mb="10px" maxWidth={450}>
                {`Hi ${user.firstName}, our brand promise is to give members the ultimate hospitality
                experience, always putting service first. We want you to know we’re listening and your
                feedback is essential in helping Mindspace strive for excellence. Please share your
                feedback, ideas, and suggestions freely and we will make sure they reach the right teams.
                If you have a specific issue or question, please reach us by submitting a `}
                <Typography
                  pointer
                  variant="proximaNova-600"
                  color={theme.color.darkGreen}
                  display="inline"
                  onClick={handleSupportClick}
                >
                  support case
                </Typography>
                .
              </Typography>
              <TextArea
                withBorder
                height="150px"
                width="450px"
                fontSize={[12, 14, 16]}
                value={text}
                onChange={onTextChange}
                placeholder="Add your feedback here"
              />
              <Button
                disabled={!text}
                onClick={handleSubmitClick}
                py={12}
                my={20}
                variant="primary"
                width={140}
              >
                Submit
              </Button>
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              maxWidth={300}
              flexDirection="column"
              px={50}
              pb={15}
            >
              <Icon mb={[15, 25]} SVG={ThankYou} />
              <Typography textAlign="center" mb={20} variant="proximaNova-600" fontSize={20}>
                Thank you!
              </Typography>
              <Typography
                textAlign="center"
                mb={20}
                variant="proximaNova-400"
                fontSize={14}
                color={theme.color.gray[200]}
              >
                Your feedback is appreciated! We will respond really soon.
              </Typography>
              <Button onClick={handleDoneClick} py={12} width="100%" variant="primary">
                To Homepage
              </Button>
            </Flex>
          ) }
          <Flex
            height="fit-content"
            border="1px solid #D9D9D9"
            maxWidth="580px"
            width="100%"
            px={24}
            py={30}
            ml={[0, 0, 0, 30]}
          >
            <Flex
              width="100%"
              height="170px"
              maxWidth="170px"
              background={`url(${AmenitiesImage}) no-repeat top`}
              backgroundSize="cover"
              mr={30}
            />
            <Flex flexDirection="column">
              <Typography mb={11} variant="garamond-500" fontSize={[18, 20]}>
                We’re here for you
              </Typography>
              <Typography mb="7px" variant="proximaNova-400" fontSize={[10, 12, 16]}>
                Need anything from the community team?
                <br />
                Any issues with something in your building?
                <br />
                Don’t hesitate to reach out!
              </Typography>
              <Flex py={10} alignItems="center" pointer onClick={handleSupportClick}>
                <Typography variant="proximaNova-600" fontSize={[10, 12, 16]} color={theme.color.darkGreen}>
                  Get Support
                </Typography>
                <Icon ml="20px" color={theme.color.darkGreen} SVG={Arrow} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Main>
  );
};

Feedback.defaultProps = {
  user: {},
  onSubmit: () => {},
  isLoading: false,
  isSuccess: false,
};

Feedback.propTypes = {
  user: shape({
    firstName: string,
  }),
  onSubmit: func,
  isLoading: bool,
  isSuccess: bool,
};

export default Feedback;
