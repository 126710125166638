import React, { useState, useRef, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';

import { Icon, Avatar } from '@atoms';
import { LocationChanger, SettingsModal, Navigation } from '@organisms';
import { Flex, Typography } from '@mixins';
import { useOutsideClick } from '@hooks';
import { theme } from '@styles';
import { routes } from '@constants';
import { storage } from '@utils';
import { COMPANY_ADMIN_ROLE } from '@constants/common';

import { resetStore as resetUserStore } from '@store/user/duck';
import { resetStore as resetCompanyStore } from '@store/company/duck';
import { resetStore as resetLocationStore } from '@store/location/duck';

import { ReactComponent as Logo } from '@assets/svgs/Logo.svg';
import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Home } from '@assets/svgs/Home.svg';

import { DropdownItem, LogoImg } from './styles';

const Header = () => {
  const avatarRef = useRef();
  const dropdownRef = useRef();

  const { isOpen, setIsOpen } = useOutsideClick(false, [
    avatarRef,
    dropdownRef,
  ]);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  const {
    user: { role, firstName, lastName, imageUrl, id, company },
  } = useSelector(store => store.userStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleHomeClick = useCallback(() => {
    history.push(routes.home);
  }, []);

  const handleSettingModalChangeState = useCallback(() => {
    setIsSettingModalOpen(prevState => !prevState);
  }, []);

  const onDropdownClick = useCallback(() => {
    setIsOpen(prevValue => !prevValue);
  }, []);

  const onMyProfileClick = useCallback(() => {
    onDropdownClick();
    history.push(`${routes.userProfile}/${id}`);
  }, [id]);

  const onCompanyProfileClick = useCallback(() => {
    onDropdownClick();
    history.push(`${routes.companyProfile}/${company?.id}`);
  }, [company]);

  const onManageTeamClick = useCallback(() => {
    onDropdownClick();
    history.push(routes.companyMembersApp);
  }, [company]);

  const onLogOut = useCallback(() => {
    storage.clear();
    Auth.signOut();
    dispatch(resetUserStore());
    dispatch(resetLocationStore());
    dispatch(resetCompanyStore());
    history.push(routes.login);
  }, []);

  const onSettingsClick = useCallback(() => {
    onDropdownClick();
    handleSettingModalChangeState();
  }, []);

  const dropdown = [
    {
      id: 1,
      title: 'My Profile',
      handler: onMyProfileClick,
      isVisible: true,
    },
    {
      id: 2,
      title: 'Company Profile',
      handler: onCompanyProfileClick,
      isVisible: role === COMPANY_ADMIN_ROLE,
    },
    {
      id: 3,
      title: 'Manage Team Members',
      handler: onManageTeamClick,
      isVisible: role === COMPANY_ADMIN_ROLE,
    },
    {
      id: 4,
      title: 'Settings',
      handler: onSettingsClick,
      isVisible: true,
    },
    {
      id: 5,
      title: 'Log Out',
      handler: onLogOut,
      isVisible: true,
    },
  ];

  const dropdownBlock = useMemo(
    () =>
      dropdown.reduce((acc, { title, id: key, handler, isVisible }, index) => {
        if (isVisible) {
          acc.push(
            <DropdownItem
              width="100%"
              px={[10, 14]}
              py={['8px', 10]}
              key={key}
              onClick={handler}
              pointer
            >
              <Typography
                variant="proximaNova-400"
                fontSize={['10px', '12px', '16px']}
                color={
                  index + 1 === dropdown.length
                    ? theme.color.darkGreen
                    : theme.color.gray[500]
                }
              >
                {title}
              </Typography>
            </DropdownItem>,
          );
        }

        return acc;
      }, []),
    [dropdown, role],
  );

  return (
    <Flex
      backgroundColor={theme.color.white}
      borderBottom={`1px solid ${theme.color.gray[200]}`}
      position="absolute"
      top={0}
      right={0}
      left={0}
      justifyContent="space-between"
      alignItems="center"
      pl={currentLocation?.isWhiteLabeled ? 0 : ['8px', 40, 64]}
    >
      {currentLocation?.isWhiteLabeled && (
        <Flex alignItems="center">
          <Flex
            px={[10, 15, 20]}
            borderRight={`1px solid ${theme.color.gray[200]}`}
          >
            <Icon pointer onClick={handleHomeClick} SVG={Home} />
          </Flex>
          {currentLocation?.logo ? (
            <LogoImg src={currentLocation?.logo} alt="logo" />
          ) : (
            <Typography
              ml={[10, 15, 20]}
              variant="proximaNova-600"
              fontSize={[14, 16, 18]}
            >
              {currentLocation?.title}
            </Typography>
          )}
        </Flex>
      )}
      {!currentLocation?.isWhiteLabeled && (
        <Flex alignItems="center">
          <Flex
            pr={['8px', 15, 24]}
            borderRight={`1px solid ${theme.color.gray[200]}`}
            pointer
            width={[75, 140, 171]}
            onClick={handleHomeClick}
          >
            <Icon SVG={Logo} />
          </Flex>
          <LocationChanger currentLocation={currentLocation?.title} />
        </Flex>
      )}
      <Flex alignItems="center">
        <Flex id="avatar" alignItems="center" ref={avatarRef}>
          {imageUrl && firstName && lastName ? (
            <Flex
              ref={avatarRef}
              pointer
              minWidth={[18, 24, 32]}
              height={[18, 24, 32]}
              backgroundImage={`url(${imageUrl})`}
              backgroundSize="cover"
              borderRadius="50%"
              onClick={onDropdownClick}
            />
          ) : (
            <Avatar
              width={[18, 24, 32]}
              onClick={onDropdownClick}
              height={[18, 24, 32]}
              imageUrl={imageUrl}
              firstName={firstName}
              lastName={lastName}
              fontSize={['8px', '10px', '12px']}
            />
          )}
          <Icon
            ml="8px"
            onClick={onDropdownClick}
            color={theme.color.black}
            pointer
            SVG={Dropdown}
            width={[6, 8, 10]}
            height={[4, 6]}
            mr={[10, 21, 27]}
          />
        </Flex>
        <Navigation companyName={company?.name} />
      </Flex>
      {isOpen && (
        <Flex
          backgroundColor={theme.color.white}
          ref={dropdownRef}
          zIndex={5}
          boxShadow="0px 4px 9px rgba(0, 0, 0, 0.15)"
          position="absolute"
          top={[35, 46, 60]}
          right={[35, 46, 58]}
          p="8px"
          flexDirection="column"
          alignItems="start"
        >
          {dropdownBlock}
        </Flex>
      )}
      {isSettingModalOpen && (
        <SettingsModal
          isOpen={isSettingModalOpen}
          onModalClose={handleSettingModalChangeState}
        />
      )}
    </Flex>
  );
};

export default Header;
