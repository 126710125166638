import { routes } from '@constants';
import { historyRef } from '@router/Router';
import { ADMIN_ROLE, COMMUNITY_MANAGER_ROLE, CMS_EMPLOYEE_ROLE, EMPLOYEE_ROLE } from '@constants/common';
import { trackEvent } from '@utils/mixpanel';

const getIsShown = (action, user, companies, currentLocation) => {
  switch (action) {
    case 'getBuildingAmenities':
      return !!currentLocation.locationToAmenity?.length;
    case 'buyCredits':
      return (user.isAbleToPayByCard || user.isAbleToInvoice)
          && user.role !== COMMUNITY_MANAGER_ROLE
          && user.role !== ADMIN_ROLE
          && user.role !== CMS_EMPLOYEE_ROLE
          && user.role !== EMPLOYEE_ROLE;
    case 'getPaymentSettings':
      return user.isAbleToPayByCard
          && user.role !== COMMUNITY_MANAGER_ROLE
          && user.role !== ADMIN_ROLE
          && user.role !== CMS_EMPLOYEE_ROLE
          && user.role !== EMPLOYEE_ROLE;
    case 'getYourNeighbors':
      return !!companies;
    case 'getRulesProcedures':
      return currentLocation.details?.buildingProcedures;
    default:
      return true;
  }
};

const navHandlersMap = {
  getBuildingAmenities: () => () => historyRef?.current?.push(routes.amenities),
  buyCredits: () => () =>
    historyRef?.current?.push({
      pathname: routes.creditsPackages,
      state: {
        from: window.location.pathname,
      },
    }),
  getPaymentSettings: () => () => historyRef?.current?.push(routes.paymentSettings),
  getBookingHistory: () => () => historyRef?.current?.push(routes.bookingHistory),
  getSupport: () => () => {
    trackEvent('"Get Support" button clicked (navigation)', {
      scope: 'Home',
    });
    historyRef?.current?.push(routes.support);
  },
  getYourNeighbors: () => () => historyRef?.current?.push(routes.knowYourNeighbors),
  getRulesProcedures: () => () => historyRef?.current?.push(routes.houseRules),
  getFeedback: () => () => historyRef?.current?.push(routes.feedback),
};

export const getNavItems = (rawItems = [], user, companies, currentLocation) => {
  const filteredSortedItems = rawItems
    .filter(item => item.showOnWeb)
    .sort((item1, item2) => item1.position - item2.position);

  return filteredSortedItems.map(item => {
    const handler = navHandlersMap[item.action]
      ? navHandlersMap[item.action](item.actionParameters)
      : null;

    return {
      title: item.title,
      handler: item.link ? () => window.open(item.link) : handler,
      isShown: getIsShown(item.action, user, companies, currentLocation),
    };
  });
};
