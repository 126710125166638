import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Events as View } from '@views';
import { getEvents, getEventsCategories, getEventsNearby } from '@store/event/duck';
import { trackEvent } from '@utils/mixpanel';
import { LOADING } from '@constants/requestPhase';

const Events = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { currentLocation: { id } } = useSelector(store => store.locationStore);
  const {
    getEventsPhase, categories, getEventsCategoriesPhase, getEventsNearbyPhase,
  } = useSelector(store => store.eventStore);

  const [selectedCategory, setSelectedCategory] = useState(location.state?.selectedCategory);

  const handleSelectedCategoryChange = category => () => {
    setSelectedCategory(category);

    trackEvent('Events category selected', {
      scope: 'Events',
      Category: categories.find(cat => cat.id === category).name,
    });
  };

  useEffect(() => {
    const pageTitle = 'Mindspace: Events';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Events',
    });

    if (!categories.length) {
      dispatch(getEventsCategories());
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEvents({
        locationId: id,
        ...(selectedCategory && { categoryId: selectedCategory }),
      }));
      dispatch(getEventsNearby({
        locationId: id,
        ...(selectedCategory && { categoryId: selectedCategory }),
      }));
    }
  }, [id, selectedCategory]);

  return (
    <View
      isLoading={getEventsCategoriesPhase === LOADING}
      areEventsLoading={getEventsPhase === LOADING || getEventsNearbyPhase === LOADING}
      selectedCategory={selectedCategory}
      onSelectedCategoryChange={handleSelectedCategoryChange}
    />
  );
};

export default Events;
