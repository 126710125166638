import React, { useMemo } from 'react';
import { func, number, arrayOf, exact, string } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { getCurrency } from '@helpers/common';
import { theme } from '@styles';

import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';

import { Package } from './styles';

const PackagesList = ({ onPackageClick, packages, selectedPackageId }) => {
  const packagesBlock = useMemo(() => packages.map(({ id, currency, price, amount }, index) => {
    const pricePerCredit = Number(price) / Number(amount);

    return (
      <Package
        pointer
        isSelected={selectedPackageId === id}
        width="100%"
        mb={index === packages.length - 1 ? 0 : '10px'}
        justifyContent="space-between"
        alignItems="center"
        py={['8px', '13px']}
        px={['12px', '20px']}
        border={`1px solid ${selectedPackageId === id ? theme.color.darkGreen : '#EFECE6'}`}
        key={id}
        onClick={onPackageClick(id)}
      >
        <Flex alignItems="center">
          <Icon SVG={Credits} width={['20px', '24px']} height={['20px', '24px']} mr={['10px', '15px']} />
          <Typography fontSize={[10, 12, 14]} variant="proximaNova-400">{`${amount} Credits`}</Typography>
        </Flex>
        <Flex flexDirection="column" alignItems="end">
          <Typography mb="4px" fontSize={[10, 12, 14]} variant="proximaNova-400">
            {getCurrency(currency, price)}
          </Typography>
          <Typography fontSize={[10, 12, 14]} variant="proximaNova-400">
            {`(${getCurrency(currency, pricePerCredit, 2)} per credit)`}
          </Typography>
        </Flex>
      </Package>
    );
  }), [packages, selectedPackageId]);

  return packagesBlock;
};

PackagesList.propTypes = {
  selectedPackageId: number,
  onPackageClick: func.isRequired,
  packages: arrayOf(
    exact({
      id: number,
      currency: string,
      amount: number,
      vatCalculated: number,
      totalPrice: number,
      price: number,
    }),
  ).isRequired,
};

export default PackagesList;
