import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { CreditsPackages as View } from '@views';
import { routes } from '@constants';
import { LOADING } from '@constants/requestPhase';
import { trackEvent } from '@utils/mixpanel';
import { getCreditsPackages, invoiceMe } from '@store/payment/duck';

const CreditsPackages = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    packages, alreadyPurchasedCredits, maxCreditsPerMonth, getCreditsPackagesPhase, invoiceMePhase,
  } = useSelector(store => (
    store.paymentStore
  ));

  const { user: { isAbleToInvoice, isAbleToPayByCard, company, isActivate } } = useSelector(store => (
    store.userStore
  ));

  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  const handleInvoiceMe = packageId => {
    dispatch(invoiceMe({ creditPackageId: packageId }));
  };

  const handleGoBack = () => {
    if (location.state?.from) {
      if (location.state?.from.includes('rooms/')) {
        history.push({ pathname: location.state?.from, state: location.state.data });
      } else {
        history.push(routes.home);
      }
    }
  };

  useEffect(() => {
    const pageTitle = 'Mindspace: Buy credits';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Credit Packages',
    });

    if (!packages.length) {
      dispatch(getCreditsPackages());
    }
  }, []);

  return (
    <View
      onGoBack={handleGoBack}
      from={location.state?.from}
      vatPercent={currentLocation?.vat}
      isLoading={getCreditsPackagesPhase === LOADING || invoiceMePhase === LOADING}
      packages={packages}
      alreadyPurchasedCredits={alreadyPurchasedCredits}
      maxCreditsPerMonth={maxCreditsPerMonth}
      onInvoiceMe={handleInvoiceMe}
      isAbleToInvoice={isAbleToInvoice}
      isAbleToPayByCard={isAbleToPayByCard}
      companyName={company?.name}
      isActivate={isActivate}
    />
  );
};

export default CreditsPackages;
