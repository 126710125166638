import React, { useCallback } from 'react';
import { arrayOf, bool, func, node, number, shape, string, boolean } from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  dataTableImageHeightInPx,
  rowPaddingYinPx,
  SuperscriptionWrap,
} from './styles';

const DEFAULT_HEIGHT = 40;
const DEFAULT_HEADER_HEIGHT = 56;

const DataTable = ({
  rows,
  withPagination,
  columns,
  superscription,
  onRowClick,
  withDefaultRowHeight,
  withDefaultHeaderHeight,
  ...props
}) => {
  const handleRowClick = useCallback(
    ({ id, row }, event) => {
      onRowClick(id, row);
      event.stopPropagation();
    },
    [onRowClick],
  );

  return (
    <>
      {superscription && (
        <SuperscriptionWrap>{superscription}</SuperscriptionWrap>
      )}
      <DataGrid
        hideFooter={!withPagination}
        autoHeight
        hideFooterSelectedRowCount
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        disableColumnMenu
        disableSelectionOnClick
        autoPageSize
        rowHeight={
          withDefaultRowHeight
            ? dataTableImageHeightInPx + rowPaddingYinPx * 2
            : DEFAULT_HEIGHT
        }
        headerHeight={
          withDefaultHeaderHeight ? DEFAULT_HEADER_HEIGHT : DEFAULT_HEIGHT
        }
        {...props}
      />
    </>
  );
};

DataTable.propTypes = {
  withPagination: boolean,
  rows: arrayOf(shape({})),
  columns: arrayOf(shape({})),
  superscription: node,
  onRowClick: func,
  onPageChange: func,
  withDefaultRowHeight: bool,
  withDefaultHeaderHeight: bool,
  paginationMode: string,
  page: number,
  rowCount: number,
};

DataTable.defaultProps = {
  withPagination: true,
  rows: [],
  columns: [],
  superscription: undefined,
  onRowClick: () => null,
  onPageChange: () => null,
  withDefaultRowHeight: true,
  withDefaultHeaderHeight: false,
  paginationMode: 'client',
  page: 0,
  rowCount: 0,
};

export default DataTable;
