import React from 'react';
import { getAllCountries } from 'countries-and-timezones';

import { Icon } from '@atoms';
import { REGEXP_EMAIL } from '@constants/common';
import DefaultImage from '@assets/images/DefaultImage.png';
import { ReactComponent as Checkmark } from '@assets/svgs/Checkmark.svg';

import { theme } from '@styles';

const removeValuesFromArray = (valuesArray, value) => {
  const valueIndex = valuesArray.findIndex(entry => entry === value);
  if (valueIndex === -1) {
    return;
  }
  valuesArray.splice(valueIndex, 1);
};

const getCloudinaryImageLinkBySize = (link, width = 700) => {
  const originalLink = link?.split('upload');

  if (!originalLink || originalLink.length === 1) return link;
  return `${originalLink[0]}upload/c_scale,w_${width},f_auto,q_auto,dpr_2.0${originalLink[1]}`;
};

const validateEmail = email =>
  !!String(email).toLowerCase().match(REGEXP_EMAIL);

const filterCountriesByCountryName = (searchValue, countries) =>
  countries.filter(country =>
    country.name.toLowerCase().includes(searchValue.trim().toLowerCase()));

const filterCountriesByCityName = (searchValue, countries) =>
  countries.reduce((reducedCountries, country) => {
    const cities = country.cities.filter(city =>
      city.name.toLowerCase().includes(searchValue.trim().toLowerCase()));

    return [
      ...reducedCountries,
      ...(cities.length ? [{ ...country, cities }] : []),
    ];
  }, []);

const filterCountriesByLocationName = (searchValue, countries) =>
  countries.reduce((reducedCountries, country) => {
    const cities = country.cities.reduce((reducedCities, city) => {
      const locations = city.locations.filter(location =>
        location.name.toLowerCase().includes(searchValue.trim().toLowerCase()));

      return [
        ...reducedCities,
        ...(locations.length ? [{ ...city, locations }] : []),
      ];
    }, []);

    return [
      ...reducedCountries,
      ...(cities.length ? [{ ...country, cities }] : []),
    ];
  }, []);

const getBackground = (isCompany, imageUrl) => {
  switch (true) {
    case !!imageUrl: {
      return `url(${imageUrl}) no-repeat center`;
    }
    case isCompany: {
      return `url(${DefaultImage}) no-repeat center`;
    }
    default: {
      return theme.color.pink;
    }
  }
};

const getCurrency = (currency = 'USD', price = 0, digitsAfterDot = 0) => {
  let currencySymbol;

  switch (currency) {
    case 'RON':
      currencySymbol = 'L';
      break;
    case 'PLN':
      currencySymbol = 'zł';
      break;
    default:
      // eslint-disable-next-line prefer-destructuring
      currencySymbol = price.toLocaleString('en-US', {
        currency,
        style: 'currency',
      })[0];
      break;
  }

  const formattedPrice = digitsAfterDot > 0
    ? price.toFixed(digitsAfterDot)
    : price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return currencySymbol === '$' || currencySymbol === '€'
    ? `${currencySymbol}${formattedPrice}`
    : `${formattedPrice}${currencySymbol}`;
};

const getSuffixByNumericValue = value => {
  const lastFigure = value % 10;
  const lastFigures = value % 100;

  switch (true) {
    case lastFigure === 1 && lastFigures !== 11: {
      return `${value}st`;
    }
    case lastFigure === 2 && lastFigures !== 12: {
      return `${value}nd`;
    }
    case lastFigure === 3 && lastFigures !== 13: {
      return `${value}rd`;
    }
    default: {
      return `${value}th`;
    }
  }
};

const getRSVPButtonLabel = (isAttending, hasSlots, isRecurring, status) => {
  switch (true) {
    case isAttending && !hasSlots && !isRecurring:
      return (
        <>
          <Icon mr={10} color={theme.color.white} SVG={Checkmark} />
          Attending
        </>
      );
    case hasSlots:
      return 'Book a spot';
    case status === 'full' && !hasSlots && !isRecurring:
      return 'Event Fully Booked';
    default:
      return 'RSVP';
  }
};

const getCountryNameFromCode = (code = 'US') => {
  const getCountryNames = new Intl.DisplayNames(['en'], { type: 'region' });
  return getCountryNames.of(code);
};

const convertLocations = data =>
  data.reduce((acc, location) => {
    const locCountry = acc.find(
      country =>
        country.name?.toUpperCase() === location.country?.toUpperCase(),
    );

    if (!locCountry) {
      acc.push({
        name: location.country,
        cities: [
          {
            name: location.city,
            locations: [
              {
                id: location.id,
                name: location.title,
              },
            ],
          },
        ],
      });
    } else {
      const locCity = locCountry.cities.find(
        city => city.name.toUpperCase() === location.city.toUpperCase(),
      );

      if (!locCity) {
        locCountry.cities.push({
          name: location.city,
          locations: [
            {
              id: location.id,
              name: location.title,
            },
          ],
        });
      } else {
        locCity.locations.push({
          id: location.id,
          name: location.title,
        });
      }
    }
    return acc;
  }, []);

const getCountryCodeFromName = name => {
  if (name === 'Great Britain') {
    return 'GB';
  }
  if (name === 'USA') {
    return 'US';
  }
  const allCountries = getAllCountries();
  const defaultCountry = Object.keys(allCountries).find(
    country => allCountries[country]?.name === name,
  );

  return defaultCountry ? allCountries[defaultCountry].id : null;
};

export {
  getRSVPButtonLabel,
  removeValuesFromArray,
  validateEmail,
  filterCountriesByCityName,
  filterCountriesByCountryName,
  filterCountriesByLocationName,
  getBackground,
  convertLocations,
  getSuffixByNumericValue,
  getCurrency,
  getCountryCodeFromName,
  getCountryNameFromCode,
  getCloudinaryImageLinkBySize,
};
