import React, { useEffect } from 'react';
import { node, bool, func, number, string } from 'prop-types';

import { Icon } from '@atoms';
import { Flex } from '@mixins';
import { theme } from '@styles';
import { ReactComponent as Close } from '@assets/svgs/Close.svg';

const Modal = ({
  zIndex,
  children,
  isOpen,
  onClose,
  withCloseButton,
  withPadding,
  topPosition,
  borderRadius,
  closeOnClickOutside,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
  }, [isOpen]);

  useEffect(() => () => {
    document.body.style.overflow = 'auto';
  }, []);

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    onClose();
  };

  const handleOutsideModalClick = e => {
    if (e.target.id === 'background') {
      handleClose();
    }
  };

  return isOpen && (
    <Flex
      id="background"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={zIndex}
      justifyContent="center"
      position="fixed"
      {...(closeOnClickOutside && { onClick: handleOutsideModalClick })}
      background="rgba(0, 0, 0, 0.5)"
    >
      <Flex
        position="absolute"
        top={topPosition}
        zIndex={7}
        maxWidth={[350, 500, 650]}
        p={withPadding ? [10, 20, 30] : 0}
        backgroundColor={theme.color.white}
        borderRadius={borderRadius}
      >
        {withCloseButton && (
          <Icon
            zIndex={1}
            pointer
            onClick={handleClose}
            position="absolute"
            SVG={Close}
            width={[10, 13, 16]}
            height={[10, 13, 16]}
            top={[16, 21, 26]}
            right={[16, 21, 26]}
          />
        )}
        {children}
      </Flex>
    </Flex>
  );
};

Modal.defaultProps = {
  withCloseButton: true,
  onClose: () => {},
  topPosition: 100,
  borderRadius: 'none',
  closeOnClickOutside: false,
  withPadding: true,
  zIndex: 10,
};

Modal.propTypes = {
  withPadding: bool,
  zIndex: number,
  isOpen: bool.isRequired,
  children: node.isRequired,
  onClose: func,
  withCloseButton: bool,
  topPosition: number,
  borderRadius: string,
  closeOnClickOutside: bool,
};

export default Modal;
